.App {
  color: #1c2025;
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.headerImage {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}

.navButtonContainer {
  text-align: center;
  margin-top: 30px;
}

.confirmPageList {
  list-style-type: none;
  padding-left: 2px;
}

.navButton {
  /* !important required here as MUI button will overwrite setting margin in CSS class otherwise */
  margin: calc(10px + 3.5vmin) !important;
  width: 120px;
}

.commonPageHeader {
  float: left;
  font-size: calc(10px + 2vmin);
}

.confirmPageHeaders {
  font-size: calc(10px + 2vmin);
  margin: 10px 0px 10px 0px;
}

.firstPageHeader {
  text-align: center;
  font-size: calc(10px + 2vmin);
}

.informationalContent {
  text-align: center;
  white-space: pre-line;
  font-size: calc(10px + 1.5vmin);
}

.recipientNameContent {
  float: left;
  margin-block-end: 1.5em;
  font-size: calc(10px + 1vmin);
}

.pageFooter {
  text-align: center;
  margin-top: 10%;
}

.App-link {
  color: #61dafb;
}

.formHelperText {
  white-space: pre-line;
}

.fillerDiv {
  width: 100%;
}

.centerText {
  text-align: center;
}

.errorBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}